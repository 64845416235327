import './App.css';
import React, { Component } from 'react';
import {Icon, Style, Text, Fill, Stroke} from 'ol/style';
import 'ol/ol.css';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { buffer, containsCoordinate} from 'ol/extent';
import View from 'ol/View';
import {Image as ImageLayer, Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {linear} from 'ol/easing'
import XYZ from 'ol/source/XYZ';
import Markers from './data/coords.json'
import NameMappings from './data/name_mapping.json'
import { FaMinus, FaPlus, FaCog, FaRegMap, FaRegCompass } from 'react-icons/fa' //FaInfo
import {MdOpenInBrowser, MdOutlineLocationSearching, MdOutlineMyLocation} from 'react-icons/md'
import Settings from './Settings.js'
// import 'leaflet-rotatedmarker';
import Nodes from './data/nodes.json'
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import ButtonTooltip from './components/ButtonTooltip'
import PlayerColours from './data/colours'
import { connect } from 'react-redux'
import trackedPlayer from './features/locationSharing/trackedPlayer'
import Path from 'ol-ext/featureanimation/Path'
import LineString from 'ol/geom/LineString';

var prev_coord = [0, 0]
var prev_angle = 0
var errors = 0
var current_zoom = 1

const excluded = {
  "dye": false,
  // "document": false
}

const max_zoom = 8
const min_zoom = 2

const y_scale = 0.000408856
const y_offset = 6143.7-2

const x_scale = 0.000408767
const x_offset = 8192.2



// example node data: {"category": "chests", "category_name": "Chests", "img": "se4", "image_name": "supply_3", "name": "Supply Chest (Elite) t4 ", "enabled": true, "label": "Supply Chest (Elite) t4 ", "value": "supply_3"}

function getStyles(base_scale) {
  var iconStyles = {}
  for (const node in Nodes){
    Nodes[node]["children"].forEach(item => {
      if ("children" in item){
        item["children"].forEach(item2 => {
          var scale = base_scale
          if((item2.image_name == "document") || (item2.image_name.startsWith("hotspot"))){
            scale = scale*0.6
          } else if (item2.image_name.startsWith("honey")){
            scale = scale*0.8
          }
          iconStyles[item2.name] = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: '/icons/'+item2.image_name+'_compass.png',
              scale: scale,
              name: item2.name,
            }),
          });
        })
      } else {
        var scale = base_scale
        if((item.image_name == "document") || (item.image_name.startsWith("hotspot"))){
          scale = scale*0.6
        } else if (item.image_name.startsWith("honey")){
          scale = scale*0.8
        }
        iconStyles[item.name] = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: '/icons/'+item.image_name+'_compass.png',
            scale: scale,
            name: item.name,
          }),
        });
      }


      
    })
  }
  return iconStyles
}

function toMapCoords([x, y]) {
  return [ (x-x_offset)/x_scale, (y-y_offset)/y_scale ]
}

function toGameCoords([x, y]) {
  return [ (x*x_scale+x_offset), (y*y_scale+y_offset)]
}

const iconHeightLookup = {2: 10, 3: 15, 4: 20, 5: 30, 6: 40, 7: 50, 8: 60}

const iconZoomScale = {2: 0.2, 3: 0.3, 4: 20, 5: 30, 6: 40, 7: 50, 8: 60}

const city_boundaries = {
  CK: {y_min: 1830, y_max: 2008, x_min: 7850, x_max: 8014},
  FL: {y_min: 621, y_max: 808, x_min: 8750, x_max: 8940},
  WW: {y_min: 2596, y_max: 2795, x_min: 9255, x_max: 9421},
  EF: {y_min: 4150, y_max: 4290, x_min: 8815, x_max: 9016},
  MB: {y_min: 3620, y_max: 3804, x_min: 7280, x_max: 7449},
  RW: {y_min: 3213, y_max: 3386, x_min: 10900, x_max: 11085},
  RS: {y_min: 4372, y_max: 4527, x_min: 12900, x_max: 13060},
  MD: {y_min: 6875, y_max: 7068, x_min: 13085, x_max: 13284},
  WF: {y_min: 5242, y_max: 5403, x_min: 11370, x_max: 11506},
  BW: {y_min: 6258, y_max: 6449, x_min: 9490, x_max: 9681},
  ER: {y_min: 5310, y_max: 5477, x_min: 7176, x_max: 7351},
  VH: {y_min: 7277, y_max: 7343, x_min: 11103, x_max: 11173},
  LS: {y_min: 8488, y_max: 8544, x_min: 12048, x_max: 12108},
  EB: {y_min: 7480, y_max: 7548, x_min: 10037, x_max: 10082},
  CP: {y_min: 7907, y_max: 7984, x_min: 8814, x_max: 8907},
  MH: {y_min: 9176, y_max: 9260, x_min: 9024, x_max: 9093},
  MR: {y_min: 10288, y_max: 10363, x_min: 8888, x_max: 8962},
}



var city_zoom = 7
var world_zoom= 5
var zoom_type= "city"

class Minimap extends Component {
  constructor(props) {
		super(props);

		this.state = {
      position: [9356.67, 2693.11],
      center: [],
      enabled: ["woods", "ores", "essences", "town", "fishing", "chests", "plants", "monsters", "documents", "events", "special"],
      size: 250,
      settings: false,
      circle: "false",
      enabled_images: [],
      opacity: 1,
      compass_mode: false,
      marker_limit: false,
      AutoFollow: 0.4,
      animating: true,
		}
    this.map = null
    this.map_markers = {}
    this.iconStyles = {}
    this.MAX_MARKERS = 2000
    this.playerMarkers = {}
	}

  updateEnabled = (enabled_images) => {
    // console.log(enabled_images)
    this.setState({enabled_images: enabled_images}, this.updateMarkers)
    // this.setState({enabled_images: enabled_images}, this.updateZoom)
    if("updateEnabled" in this.props){
      this.props.updateEnabled(enabled_images)
    }
  } 

  updateOpacity = (value) => {
    this.setState({opacity: value})
  }

  updateCenter = () => {
    var center = this.map.getView().getCenter()
    var x = (center[0]*x_scale+x_offset).toFixed(2)
    var y = (center[1]*y_scale+y_offset).toFixed(2)
    // this.setState({center: [x,y]})
        // set rotation
    
    var distance = ((x-prev_coord[0])**2+(y-prev_coord[1])**2)**0.5
    if (distance > 1) {
      var angle = Math.atan((x-prev_coord[0])/(y-prev_coord[1]))*180/Math.PI
      // work out offset
      if (y-prev_coord[1] < 0){
        angle = angle+180
      }

      if ((angle < 0) && (prev_angle > 180)){
        prev_angle = prev_angle - 360
      }
      if ((prev_angle < 0) && (angle > 180)){
        prev_angle = prev_angle + 360
      }

      angle = angle + (prev_angle-angle)*10**-0.2

      if ((prev_coord[0] === 0) && (prev_coord[1] === 0)){
        angle = 0
      }
      if(this.state.compass_mode){
        const view = this.map.getView()
        // const center = view.getCenter();
        view.animate(
          {
            rotation: angle*Math.PI/180,
          }    
        );
      } else{
        if (this.props.minimap){
          document.getElementById('PlayerIcon').style.transform = "rotate(" + angle + "deg)"
        }
      }

      prev_coord = [x, y]
      prev_angle = angle
      this.maybeUpdateMarkers()
    }
  }

  updateZoom = (zoom_level) => { 
    this.updateMarkers()
  }

  EXTRA_RENDER_AMOUNT = 0.2
  last_marker_update = [0, 0]

  maybeUpdateMarkers = () => {
    const current_view = this.map.getView()
    const bounds = current_view.calculateExtent(this.map.getSize())
    const buffer_amount = (bounds[2]-bounds[0])*this.EXTRA_RENDER_AMOUNT
    const mapCoords = current_view.getCenter();
    let zoom;
    // only autozoom on minimap
    if(this.props.minimap){
      if (this.isInCity(toGameCoords(mapCoords))){
        if (zoom_type !== "city"){
          zoom_type = "city"
          zoom = city_zoom
          current_view.animate(
            {
              zoom: zoom,
              duration: 200,
            })
          setTimeout(this.maybeUpdateMarkers(), 250)  
        }
      } else {
        if (zoom_type !== "world") {
          zoom_type = "world"
          zoom = world_zoom
          current_view.animate(
            {
              zoom: zoom,
              duration: 200,
            })
          setTimeout(this.maybeUpdateMarkers(), 250)  
        }
      }
    }
    let newZoom
    if (zoom == null){
      newZoom = current_view.getZoom();
      // only update zoom marks if zoom changes more than threshold amount
      if (Math.abs(current_zoom - newZoom) > 0.4) {
        current_zoom = newZoom;
        if (zoom_type === "city"){
          city_zoom = newZoom
        } else{
          world_zoom = newZoom
        }
        this.iconStyles = getStyles(3*newZoom/20-0.3)
        this.updateMarkers();
      } else {
        if(Math.abs(mapCoords[0] - this.last_marker_update[0]) >= buffer_amount
        || Math.abs(mapCoords[1] - this.last_marker_update[1]) >= buffer_amount)
        {
          this.updateMarkers();
        }
      }
    } 
    
  }

  updateMarkers = () => {
    var startTime = performance.now()

    // for (const item in this.map_markers){
    //   this.map.removeLayer(this.map_markers[item])
    //   delete this.map_markers[item]
    // }

    const current_view = this.map.getView()
    const center =  current_view.getCenter()
    const zoom_level = current_view.getZoom()
    const bounds = current_view.calculateExtent(this.map.getSize())
    const buffer_amount = (bounds[2]-bounds[0])*this.EXTRA_RENDER_AMOUNT
    const buffered_bounds = buffer(bounds, buffer_amount);

    let features_to_add = []
    let num_markers = 0
    let marker_limit_reached = false
outer_loop:
    for (let enabled_key of this.state.enabled) {
      for (const item_name in Markers[enabled_key]) {
        var item_dict = Markers[enabled_key][item_name]
        var mapped_name = item_name
        if (item_name in NameMappings) {
          mapped_name = NameMappings[item_name].name
          var mapped_image_name = NameMappings[item_name].value
          if (this.state.enabled_images.includes(mapped_name)) {
            var iconHeight = iconHeightLookup[zoom_level]
            // var icon =  new MapIcon({
            //   iconUrl: '/icons/'+mapped_name.toLowerCase()+"_compass.png",
            //   iconSize: [iconHeight, iconHeight],
            //   iconAnchor: [iconHeight/2, iconHeight/2]})

            // For each marker
            for (const item in item_dict) {
              let mapPos = toMapCoords([item_dict[item].x, item_dict[item].y]);
              if (!containsCoordinate(buffered_bounds, mapPos))
                continue;
                var newMarker = new Feature({
                  geometry: new Point(mapPos),
                  name: mapped_name
                });
                newMarker.setStyle(this.iconStyles[mapped_name])
                features_to_add.push(newMarker)
              // var newMarker = new marker(
              //   mapPos,
              //   {
              //     icon: icon,
              //     draggable: false,
              //     keyboard: false,
              //     interactive: false,
              //   });
              // this.map_markers[item] = newMarker
              // this.map.addLayer(newMarker)
              num_markers += 1

              // Break clause, never load more than MAX_MARKERS markers
              if (num_markers >= this.MAX_MARKERS) {
                marker_limit_reached = true;
                break outer_loop
              }
            }
          }
        }
      }
    }
    if (this.state.marker_limit !== marker_limit_reached){
      this.setState({marker_limit: marker_limit_reached})
    }
    //clear current features
    this.vectorSource.clear(true)
    // add all features in one go
    this.vectorSource.addFeatures(features_to_add)
    this.last_marker_update = center
    var endTime = performance.now()
    console.log(`Enabled ${num_markers} markers in ${endTime-startTime} milliseconds`)
  }

  updateView = ([x, y, rotation]) => {
    const view = this.map.getView()

    var duration = 450
    if (this.props.minimap) {
      this.maybeUpdateMarkers()
      if(this.props.compass_mode){
        view.animate(
          {
            rotation: (rotation-90)*Math.PI/180,
            duration: duration,
            center: toMapCoords([x, y]),
          }
        );
      } else{
        view.animate({
          duration: duration,
          center: toMapCoords([x, y]),
          easing: linear,
        })
      }
    } else {
      if((this.props.TrackedPlayer !== "") && (this.props.pinned)){
        var buffer_percent = this.state.AutoFollow
        var bounds = view.calculateExtent(this.map.getSize())
        //if current position within x% of edge
        var new_bounds = [0, 0, 0, 0]
        new_bounds[0] = bounds[0]+(bounds[2]-bounds[0])*buffer_percent
        new_bounds[2] = bounds[2]-(bounds[2]-bounds[0])*buffer_percent
  
        new_bounds[1] = bounds[1]-(bounds[1]-bounds[3])*buffer_percent
        new_bounds[3] = bounds[3]+(bounds[1]-bounds[3])*buffer_percent
        if (!containsCoordinate(new_bounds, toMapCoords([x, y]))){
          view.animate({
            center: toMapCoords([x, y]),
            duration: duration,
            easing: linear
          })
        }
      }
    }
  }

  updatePlayerPosition = ([x, y, rotation], playerMarker) => {
    playerMarker.x = x;
    playerMarker.y = y;
    playerMarker.rotation = rotation;
    if(playerMarker.animationController)
      playerMarker.animationController.stop()

    const [prev_x, prev_y] = toGameCoords(playerMarker.feature.getGeometry().getCoordinates())

    const feature = playerMarker.feature;
    const style = playerMarker.feature.getStyle();

    style.getImage().setRotation((90-rotation)*Math.PI/180);

    if (this.state.animating === true){
      // Start linear animation from previous position to new, duration 500ms
      playerMarker.animationController = this.vectorLayer.animateFeature(feature, 
        new Path({ // ol.featureAnimation.Path
          path: new LineString([
            toMapCoords([prev_x, prev_y]),
            toMapCoords([x, y]),
          ]),
          // rotate: true, // rotation interpolated from position
          easing: 'linear',
          duration: 500,
        })
      );
    } else {
      // With no animation:
      feature.getGeometry().setCoordinates(toMapCoords([x, y]))
      feature.getStyle().getImage().setRotation((90-rotation)*Math.PI/180);
      feature.changed()
    }
  }

  zoomIn = () => {
    const current_view = this.map.getView()
    var zoom_level = current_view.getZoom();
    if (zoom_level < max_zoom){
      zoom_level += 1
      current_view.animate(
        {
          zoom: zoom_level,
          duration: 200,
        })
       
      setTimeout(this.maybeUpdateMarkers(), 250)  
    }
  }

  zoomOut = () => {
    const current_view = this.map.getView()
    var zoom_level = current_view.getZoom();
    if (zoom_level > min_zoom){
      zoom_level -= 1
      current_view.animate(
        {
          zoom: zoom_level,
          duration: 200,
        })
      setTimeout(this.maybeUpdateMarkers(), 250)  
    }
  }

  updateSize = (new_size) => {
    if (this.state.size != new_size){
      this.setState({size: new_size})
      setTimeout(() => { this.map.updateSize();}, 50);
    }
    
  }

  toggleShape = (value) => {
    if (this.state.circle != value){
      
      var border_radius="0%"
      if (value === "true"){
        border_radius="50%"
      }
      var elements = document.querySelectorAll(".ol-viewport");
      elements[0].style.borderRadius = border_radius;
      this.setState({circle: value})
    }
  }

  toggleSettings =() => {
    var next_settings = !this.state.settings
    this.setState({settings: next_settings})
  }

  togglePinned = () => {
    this.props.togglePinned()
  }

  unPin = () => {
    this.props.unpin()
  }

  updatePlayerMarkers = () => {
    for (let playerId in this.props.locations) {
      let { playerName: playerName, position: [x, y, rotation] } = this.props.locations[playerId]
      //comment out next line for debugging
      if (playerName === this.props.playerName) continue;

      if (!(playerId in this.playerMarkers)) {
        const playerNumber = Object.keys(this.playerMarkers).length
        const feature = this.createPlayerFeatureMarker(playerNumber, playerName)
        feature.getGeometry().setCoordinates(toMapCoords([x, y]))
        feature.getStyle().getImage().setRotation((90-rotation)*Math.PI/180);
        this.playerVector.addFeature(feature)

        this.playerMarkers[playerId] = {
          x: x,
          y: y,
          rotation: y,
          feature: feature,
        }
      }
      this.updatePlayerPosition([x, y, rotation], this.playerMarkers[playerId])
    }

    // Delete unneeded features
    for (let playerId in this.playerMarkers) {
      if (!(playerId in this.props.locations)) {
        this.playerVector.removeFeature(this.playerMarkers[playerId].feature)
        delete this.playerMarkers[playerId]
      }
    }
  }

  createPlayerFeatureMarker(num, playerName) {
    var player_number = num
    if(player_number > (PlayerColours.length-1)){
      player_number = player_number%(PlayerColours.length)
    }

    const playerMarkerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        color: PlayerColours[player_number],
        src: '/icons/player.png',
        scale: 0.5,
        rotation: 0,
        rotateWithView: true,
      }),
      text: new Text({
        offsetY: 20,
        font: '12px Poppins',
        fill: new Fill({ color: '#f0f0f0' }),
        stroke: new Stroke({
          color: '#333', width: 1
        }),
        // get the text from the feature - `this` is ol.Feature
        // and show only under certain resolution
        text: playerName
      })
    })
    let feature = new Feature({
      geometry: new Point([0, 0]),
      name: playerName,
    });
    feature.setStyle(playerMarkerStyle)
    return feature;
  }

  isInCity = ([x,y]) => {
    for (const city in city_boundaries){
      if ((x < city_boundaries[city].x_max) && (x > city_boundaries[city].x_min)){
        if ((y < city_boundaries[city].y_max) && (y > city_boundaries[city].y_min)) {
          return true;
        }
      }
    }
    return false;
  }

  componentDidUpdate =(prevProps, prevState) => {
    if (this.props.enabled !== prevProps.enabled){
      // console.log("updating_enabled")
      this.updateEnabled(this.props.enabled)
    }
    if(this.props.locations !== prevProps.locations){
      this.updatePlayerMarkers()
    }
    if (this.props.minimized !== prevProps.minimized) {
        this.map.updateSize()
      }
    const TrackedPlayer = this.props.locations[this.props.TrackedPlayer]
    if (this.props.pinned !== prevProps.pinned || this.props.TrackedPlayer !== prevProps.TrackedPlayer) {
      if (TrackedPlayer) {
        this.updateView(TrackedPlayer.position)
      }
    }
    else {
      // If coordinates of tracked player are updated
      let prevPosition = prevProps.locations[this.props.TrackedPlayer]?.position;
      if (TrackedPlayer && prevPosition && ((TrackedPlayer.position[0] !== prevPosition[0]) || (TrackedPlayer.position[1] !== prevPosition[1]) || (TrackedPlayer.position[2] !== prevPosition[2])) && TrackedPlayer.playerName !== this.props.playerName) {
        this.updateView(TrackedPlayer.position)
      }
    }
  }

  updateAutoFollow = (AutoFollow) => {
    this.setState({AutoFollow: AutoFollow})
  }

  updateAnimating = (animating) => {
    console.log("Update Animating: ", animating)
    this.setState({animating: animating})
  }

  displayFeatureInfo = (pixel) => {
    this.info.style.left = pixel[0] + 'px'
    this.info.style.top = pixel[1] - 15 + 'px'
    const feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
      return feature;
    });
    if (feature) {
      this.info.style.display = 'block'
      this.info.innerHTML = feature.get('name')
    } else {
      this.info.style.display = 'none'
    }
  };

  componentDidMount() {
    //tooltips
    this.info = document.querySelector("#info");
    console.log("info:", this.info)
    // this.info.tooltip({
    //   animation: false,
    //   trigger: 'manual',
    // });

    this.iconStyles = getStyles(0.8)

    var stored_autofollow = localStorage.getItem("NWMM_AutoFollow")
    if (stored_autofollow !== null){
      this.setState({AutoFollow: stored_autofollow})
    }
    var stored_animating = localStorage.getItem("NWMM_Animating")
    if (stored_animating == "false"){
      this.setState({animating: false})
    }

    var center = this.state.position
    if (this.props.locations){
      if (this.props.locations.local) {
        center = this.props.locations.local.position.slice(0,2)
      } else {
        if (this.props.TrackedPlayer in this.props.locations){
          center = this.props.locations[this.props.TrackedPlayer].position.slice(0,2)
        }
      }
    }

    if (!this.props.minimap) {
      this.MAX_MARKERS = 4000
    }

    this.playerVector = new VectorSource({
      features: [],
    })

    this.playerVectorLayer = new VectorLayer({
      source: this.playerVector,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });
    this.playerVectorLayer.setZIndex(1000);

    this.vectorSource = new VectorSource({
      features: [],
    });
    
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      renderMode: "vector",
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    const layers = [
      new TileLayer({
        source: new XYZ({
        url: 'https://cdn.newworldminimap.com/file/nwminimap/09-24/{z}/{x}/{y}.png',
        minZoom: 2,
        maxZoom: 8
        }),
      }),
      this.vectorLayer,
      this.playerVectorLayer
  ]

  this.view = new View({
    center: toMapCoords(this.state.position), //[-472202, 7530279],
    zoom: 7,
  })

    this.map = new Map({
      target: 'mapid',
      layers: layers,
      // controls: defaultControls().extend([mousePositionControl]),
      view: this.view,
    });


    

    // this.updateMarkers()

    this.map.on('postrender', this.updateCenter);
    this.map.on('moveend', this.maybeUpdateMarkers);
    this.map.on('pointerdrag', this.unPin);
    
    if (this.props.minimap){
      this.toggleShape("true")
    } else {
      this.toggleShape("false")
    }
    
    //disable event propogation
    // this.map.dragging.disable();
    // this.map.touchZoom.disable();
    // this.map.doubleClickZoom.disable();
    // this.map.scrollWheelZoom.disable();
    // this.map.boxZoom.disable();
    // this.map.keyboard.disable();
    // if (this.map.tap) this.map.tap.disable();
    var checked_list = []
    var old_markers = localStorage.getItem("NWWM_WebMarkers")
    if (old_markers !== null){
      checked_list = JSON.parse(old_markers)
    } else {
      for (const node in Nodes){
        // if (node.value == "town"){
        Nodes[node]["children"].forEach(item => {
          if ("children" in item){
            item["children"].forEach(item2 => {
              checked_list.push(item2.value)
            })
          } else {
            checked_list.push(item.value)
          } 
        })
      }
      // }
    }
    this.updateEnabled(checked_list)

    this.map.on('pointermove',  (evt) => {
      if (evt.dragging) {
        this.info.style.display = 'none'
        return;
      }
      this.displayFeatureInfo(this.map.getEventPixel(evt.originalEvent));
    });
    // this.updateMarkers()
    // this.updatePlayerMarkers()
  }
  // MapBorderCircle
 render(){
  
  var settings
  if (this.state.settings == true){
    settings = <Settings updateSize = {this.updateSize.bind(this)} toggleSettings={this.toggleSettings.bind(this)} toggleShape={this.toggleShape.bind(this)} circle={this.state.circle} size={this.state.size} updateEnabled={this.updateEnabled.bind(this)} Nodes={Nodes} enabled = {this.state.enabled_images} updateOpacity={this.updateOpacity.bind(this)} opacity={this.state.opacity} updateAutoFollow={this.updateAutoFollow.bind(this)} AutoFollow={this.state.AutoFollow} id="settingsId" second_screen={!this.props.minimap} animating={this.state.animating} updateAnimating={this.updateAnimating.bind(this)}/>
  }

  var buttons
  var MapOverlayClassName = "MapOverlay"
  var MapContainerStyle = {cursor: "default", position: "relative"}
  if ((this.state.circle === "true") && (this.props.minimap)){
    buttons = [
    <ButtonTooltip title="Settings" placement="right">
      <div className="MapButton1" style={{fontSize: "12px"}} onClick={this.toggleSettings} key="MapButton1">
        <FaCog/>
      </div>
    </ButtonTooltip>,
    <ButtonTooltip title="Zoom In" placement="right">
      <div className="MapButton2" onClick={this.zoomIn} key="MapButton2">
        <FaPlus/>
      </div>
    </ButtonTooltip>,
    <ButtonTooltip title="Zoom Out" placement="right">
      <div className="MapButton3" onClick={this.zoomOut} key="MapButton3">
        <FaMinus/>
      </div>
    </ButtonTooltip>,
    //  <ButtonTooltip title="Full Screen Map" placement="right">
    //       <div className="MapButton4" onClick={this.toggleMap}>
    //       <FaRegMap/>
    //     </div>
    // </ButtonTooltip>,
    // <ButtonTooltip title="Stream Location" placement="right">
    //      <div className="MapButton5" onClick={this.StreamLocation}>
    //      <MdOpenInBrowser/>
    //    </div>
    // </ButtonTooltip>
    ]
    MapOverlayClassName = "MapOverlayCircle"
    MapContainerStyle = {cursor: "default", borderRadius: "50%", position: "relative"}
  } else {
    var MapOverlayClassName = "MapOverlay"
    var MapContainerStyle = {cursor: "default", borderRadius: "0%", position: "relative"}
    if(!this.props.minimap){

      var following_player_icon = <MdOutlineLocationSearching style={{color: "#c2403c"}}/>
      if(this.props.pinned){
        following_player_icon = <MdOutlineMyLocation style={{color: "#cfcfcf"}}/>
      }
      buttons =         
      <div className="MapButtons">
        <ButtonTooltip title="Settings" placement="right">
          <div className="MapButton" style={{fontSize: "12px"}} onClick={this.toggleSettings} >
            <FaCog/>
          </div>
        </ButtonTooltip >
        <ButtonTooltip title="Zoom In" placement="right">
          <div className="MapButton" onClick={this.zoomIn}>
            <FaPlus style={{fontSize: "12px"}}/>
          </div>
        </ButtonTooltip >
        <ButtonTooltip title="Zoom Out" placement="right">
          <div className="MapButton" onClick={this.zoomOut}>
            <FaMinus style={{fontSize: "12px"}}/>
          </div>
        </ButtonTooltip>
        {/* <ButtonTooltip title="Toggle Minimap" placement="right">
            <div className="MapButton" onClick={this.toggleMap}>
            <FaRegCompass />
          </div>
        </ButtonTooltip> */}
        <ButtonTooltip title="Follow Player" placement="right">
            <div className="MapButton" onClick={this.togglePinned}>
            {following_player_icon}
          </div>
        </ButtonTooltip>
      </div>
    } else {
      buttons =         
      <div className="MapButtons">
        <ButtonTooltip title="Settings" placement="right">
          <div className="MapButton" style={{fontSize: "12px"}} onClick={this.toggleSettings} >
            <FaCog/>
          </div>
        </ButtonTooltip >
        <ButtonTooltip title="Zoom In" placement="right">
          <div className="MapButton" onClick={this.zoomIn}>
            <FaPlus/>
          </div>
        </ButtonTooltip >
        <ButtonTooltip title="Zoom Out" placement="right">
          <div className="MapButton" onClick={this.zoomOut}>
            <FaMinus/>
          </div>
        </ButtonTooltip>
        {/* <ButtonTooltip title="Full Screen Map" placement="right">
            <div className="MapButton" onClick={this.toggleMap}>
            <FaRegMap/>
          </div>
      </ButtonTooltip> */}
      </div>
    }

  }
  // var devicePixelRatio = window.devicePixelRatio
  var MapBorderClassName = "MapBorder"
  var main_div_style = { display: "flex", alignItems:"center", justifyContent:"center"}
  var player_icon
  if (!this.props.minimap){
    main_div_style.height = "100%"
    main_div_style.width = "100%"
    MapOverlayClassName = ""
    MapBorderClassName = "MapBorder2"
  } else {
    main_div_style.height = String(this.state.size)+"px"
    main_div_style.width = String(this.state.size)+"px"
    player_icon = <img id="PlayerIcon" src="/icons/player.png"/>
  }

  var marker_limit_warning
  if(this.state.marker_limit === true){
    marker_limit_warning = <div className="MarkerLimitWarning"> Marker limit reached ({this.MAX_MARKERS} markers). Zoom in or apply some filters</div>
  }

  var devicePixelRatio = 1
  return (
    <div style={main_div_style}>
      <div className={MapBorderClassName} style={{width: (100/devicePixelRatio).toFixed(3)+"%", height: (100/devicePixelRatio).toFixed(3)+"%", transform: "scale("+String(devicePixelRatio)+")", opacity: this.state.opacity, }}>
        <div className="MapContainer" id = "mapid" style={MapContainerStyle}  >
          {/* <Map center={[0, 0]} zoom={2}  doubleClickZoom={false} crs={CRS.Simple}>
            
          </Map> */}
          </div>
          {/* <div style={{color: "#cfcfcf", position: "absolute", bottom: 2, zIndex: 9999, userSelect: "none"}}>{String(this.state.center[0])}, {String(this.state.center[1])}</div> */}
          {buttons}
          <div className={MapOverlayClassName}></div>
          <div id="info"></div>
          {player_icon}
          {marker_limit_warning}
        </div>
      {settings}
      
      </div>
  );
 }
}

const mapDispatchToProps = {
  unpin: trackedPlayer.actions.unpin,
  togglePinned: trackedPlayer.actions.togglePinned,
}

function mapStateToProps(state) {  
  const { connectedPlayers, trackedPlayer } = state;
  return {
    locations: connectedPlayers,    
    TrackedPlayer: trackedPlayer.trackedPlayerId,
    pinned: trackedPlayer.pinned,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Minimap);
